/**
 *
 *  Header Style
 *
 *  @package ohkimur_layout
 *
 **/

header.site-header {
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 1080px;
  z-index: 1;
  
  // Blog Header
  &.blog-header {
    height: 600px;

    .parallax-wrapper {
      height: 600px;
    }

    .container {
      height: 600px;
    }

    .header-banner {
      height: calc(600px - 250px);
    }
  }

  // Parallax-wrapper
  .parallax-wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;
    max-height: 1080px;
    top: 0;
    left: 0;
  }

  .container {
    position: relative;
    height: 100vh;
    max-height: 1080px;
  }

  @media (max-width: 1000px) {
    .logo {
      float: none;
    }
  }

  .cover:after {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
    background: -moz-linear-gradient(top, rgba(84,102,134,0.5) 0%, rgba(0,0,0, 0.5) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(84,102,134,0.5))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(84,102,134,0.5) 0%,rgba(0,0,0, 0.5) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(84,102,134,0.5) 0%,rgba(0,0,0, 0.5) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(84,102,134,0.5) 0%,rgba(0,0,0, 0.5) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(84,102,134,0.5) 0%,rgba(0,0,0, 0.5) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#546686', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }

  // Site Navigation Bar
  .site-navigation-bar {
    position: relative;
    height: 100px;
    min-height: 100px;
    z-index: 2;

    // Logo
    .logo {
      img {
        display: block;
        float: left;
      }
    }

    // Navigation Bar
    .navigation-bar {

      nav.nav-menu {
        position: relative;
        display: block;
        float: right;

        ul {
          li {
            float: left;
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }

            &.current-menu-item {
              a {
                color: $primary;
                position: relative;

                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 50%;
                  height: 1px;
                  left: 50%;
                  bottom: -10px;
                  transform: translateX(-50%);
                  background-color: $primary;
                }
              }
            }

            a {
              display: block;
              color: $white;
              padding: 10px 0;

              &:hover,
              &:active {
                color: $primary;
              }

            }
          }
        }

      }
    }

  }

  // Site Header Banner
  .header-banner {
    height: calc(100vh - 250px);
    max-height: calc(1080px - 250px);
    z-index: 2;
    color: $white;

    .heading-content {
      width: 100%;
      text-transform: uppercase;

      .heading-title {
        hr.small {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }

      .buttons-header {
        margin-top: 40px;

        .button-cta {
          width: 100%;
        }
      }
    }
  }

  // Arrow Down Section
  .arrow-down-section {
    position: absolute;
    width: 100%;
    height: 150px;
    bottom: 0;
    z-index: 3;

    .arrow-down {
      display: block;
      position: relative;
      font-size: 4rem;
      line-height: 4rem;
      color: $white;
      width: 64px;
      height: 64px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(45deg);
      }

      // Stroke
      &:before {
        border: 1px solid $primary;
        animation: sera-pulse $animation-arrow-header-time $animation-arrow-header-type infinite alternate;
      }

      // Background
      &:after {
        transition: background-color $transition-links $transition-links-type;
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:hover {
        i {
          transform: scale(1.3);
        }

        &:after {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      i {
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform $transition-links $transition-links-type;

        &:before {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}
