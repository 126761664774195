/**
 *
 *  Intro Alternate Style
 *
 *  @package ohkimur_layout
 *
 **/

section.intro-alternate-section {
  padding: 10px 0;
  color: $white;
  background-color: $primary;

  h2 {
    text-transform: uppercase;
    font-size: 1.75rem;
    padding: 1.125rem 0;
  }

  // Links
  a { 
    color: $white;
    
    &:hover,
    &:active {
      color: white;
    }
  }

  @media (max-width: 992px) {
    h2 {
      text-align: center;
      line-height: 1.4;
      padding: 0;
      padding-bottom: 10px;
    }
  }

  
  .cta {
    display:flex;
    justify-content:center;
    margin-top:20px;
    margin-bottom:15px;
    .button-cta {
      margin-bottom: 0;
      color: $primary;
      background-color:  $white;
    }
    
    //
  }

}
