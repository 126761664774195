/**
 *
 *  Footer Style
 *
 *  @package ohkimur_layout
 *
 **/

footer.site-footer {
  position: relative;
  color: $footer-color;
  background-color: $footer-background-color;
  width: 100%;

  .footer-award-centre {
    display: flex;
    justify-content: center;
  }

  .footer-award-left {
    display: flex;
    justify-content: left;
  }

  .footer-award-right {
    display: flex;
    justify-content: right;
  }
  
  // Footer Information
  .footer-info {
    padding: 80px 0;

    .footer-logo {
      position: relative;

      img {
        margin: 0 auto;
      }
    }

    .footer-info-text {
      font-size: 0.875rem;
      padding: 35px 0;
    }

    // Social Media Icons
    .social-icons {
      a  {
        color: $global-text;

        &:hover,
        &:active {
          color: $primary;
        }
      }
    }
  }

  // Footer Copyright
  .footer-copyright {
    font-size: 0.8125rem;
    border-top: 1px solid $normal-gray;
    padding: 30px 0;

    .footer-text {
      width: 100%;
    }
  }

}
