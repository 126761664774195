/**
 *
 *  Resdiary Style
 *
 **/

.rd-widget-content {

    .logo-panel {
        display: none;
    }
    
    .custom-logo {
        display: none;
    }
    
}