/**
 *
 *  Intro Style
 *
 *  @package ohkimur_layout
 *
 **/

section.jobs-intro-section {
  padding: 30px 0;
  color: $primary;
  background-color:  $white;

  h2 {
    text-transform: uppercase;
    font-size: 1.75rem;
    padding: 1.125rem 0;
  }

  @media (max-width: 992px) {
    h2 {
      text-align: center;
      line-height: 1.4;
      padding: 0;
      padding-bottom: 10px;
    }
  }

  .cta {
    display:flex;
    justify-content:center;
    margin-top:20px;
    margin-bottom:15px;
    .button-cta {
      margin-bottom: 0;
    }
  }

  .icon {
    
    .circle {
      width: calc(50% - 15px);
      padding-bottom: calc(50% - 23px);
      margin: 0 auto;
      margin-bottom: 40px;
      border: 4px solid $primary;
      transition: all $transition-links $transition-links-type;

      .circle {
        width: 50%;
        padding-bottom: 50%;
        border: 0;
        background: $secondary;

        i {
          font-size: 3rem;
          color: $primary;
          transition: all $transition-links $transition-links-type;

          &:before {
            padding-left: 3%;
          }
        }
      }
    }
  }
  
}
