/**
 *
 *  Intro Style
 *
 *  @package ohkimur_layout
 *
 **/

section.intro-section {
  padding: 10px 0;
  color: $primary;
  background-color:  $white;

  h2 {
    text-transform: uppercase;
    font-size: 1.75rem;
    padding: 1.125rem 0;
  }

  @media (max-width: 992px) {
    h2 {
      text-align: center;
      line-height: 1.4;
      padding: 0;
      padding-bottom: 10px;
    }
  }

  .button-cta {
    margin-bottom: 0;
  }
}
